import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { onSnapshot, collection, where, query, limit } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import Footer from "./Footer";
import { Window, Title } from '../../App';
import ProjectsItem from '../items/ProjectsItem';
import Header from './Header';
import RessourcesLinks from '../items/RessourcesLinks';

export default function Ressource() {
    const { breakpoint } = useContext(Window);
    const { setTitle } = useContext(Title);

    const [id, setId] = useState(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
    const [ressourceTitle, setRessourceTitle] = useState("");
    const [projects, setProjects] = useState([]);

    const projectsMock = [
        {
            id: 1,
            title: "Drive",
            src: "https://www.tasteofcinema.com/wp-content/uploads/2018/07/Elevator-Drive-2011.jpg",
            alt: "",
            count: 0,
        },
        {
            id: 2,
            title: "The Matrix",
            src: "https://themotionpictureblog.files.wordpress.com/2018/01/best-movie-fight-scenes.jpg",
            alt: "",
            count: 0,
        },
        {
            id: 3,
            title: "Eternal Sunshine of the Spotless Mind",
            src: "https://64.media.tumblr.com/0893156ef3618b637e58fba209858d0a/d9d7ab0ce682a421-ae/s1280x1920/7a841ec2316f6c1d6d272c694c0e537bd93af87c.jpg",
            alt: "",
            count: 0,
        },
    ];
    const projectCategories = [
        "Montage",
        "Régie",
        "VFX",
        "Photographie",
        "Parcours",
    ];

    useEffect(() => {
        const ressourcesUnsub = onSnapshot(query(collection(db, "ressources"), where("id", "==", id), limit(1)), (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                setRessourceTitle(doc.data().title);
            });
        });

        const projectsUnsub = onSnapshot(query(collection(db, "projects"), where("ressource", "==", id)), (querySnapshot) => {
            let projects = [];

            querySnapshot.forEach(async (doc) => {
                projects.push({
                    id: doc.id,
                    ressource: doc.data().ressource,
                    title: doc.data().title,
                    description: doc.data().description,
                    images: doc.data().images,
                    videos: doc.data().videos,
                    debutDate: doc.data().debutDate,
                    endDate: doc.data().endDate,
                });
            });

            setProjects(projects);
        });

        return () => {
            ressourcesUnsub();
            projectsUnsub();
        };
    }, [id]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setTitle(ressourceTitle + " - Julien Tournissa | Réalisateur, cadreur, monteur et régisseur marseillais");
    }, [ressourceTitle]);

    return (
        <React.Fragment>
            <Header
                ressource="" />
            <div className="ressource category">
                <div className="ressource__buttons">
                    <RessourcesLinks
                        setNewId={setId}
                        ressource={id} />
                </div>
                <div className={`title ${breakpoint}`}>
                    <h2 className="title__h2">
                        {ressourceTitle}
                    </h2>
                </div>
                <div className="projects__items">
                    {projects?.sort(function (a, b) { return new Date(b.debutDate) - new Date(a.debutDate) }).map(p => (
                        <ProjectsItem key={p.id}
                            item={p}
                            image={p.images[0]}
                            count={0}
                            path={"/ressource/project/"} />
                    ))}
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}