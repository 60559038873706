import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Window } from '../../App';

export default function ProjectsItem({ item, image, count, path }) {
    const navigateTo = useNavigate("");

    const { breakpoint } = useContext(Window);

    const [itemIsIntersecting, setItemIsIntersecting] = useState(false);

    const itemRef = useRef(null);

    const itemObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setItemIsIntersecting(entry.isIntersecting)
        }
    ), [itemRef]);

    useEffect(() => {
        itemObserver.observe(itemRef.current);

        return () => {
            itemObserver.disconnect();
        }
    }, []);

    return (
        <React.Fragment>
            <div className={`item ${breakpoint} ${itemIsIntersecting ? "animated" : ""}`}
                ref={itemRef}
                onClick={() => navigateTo(path + item?.id)}>
                {breakpoint === "mobile" ?
                    <React.Fragment>
                        <div className={`item__picture ${breakpoint}`}>
                            <img className={`item__picture__img ${breakpoint}`}
                                src={image}
                                alt={item.title + " cover"} />
                            <p className={`item__picture__title ${breakpoint}`}>
                                <span>
                                    {item.title}
                                </span>
                                {count > 0 &&
                                    <span>
                                        {count}
                                    </span>
                                }
                            </p>
                        </div>
                    </React.Fragment>
                    : <React.Fragment>
                        <div className={`item__picture ${breakpoint}`}>
                            <img className={`item__picture__img ${breakpoint}`}
                                src={image}
                                alt={item.title + " cover"} />
                            <p className={`item__picture__title ${breakpoint}`}>
                                <span>
                                    {item.title}
                                </span>
                                {count > 0 &&
                                    <span>
                                        {count}
                                    </span>
                                }
                            </p>
                        </div>
                        <img className="item__img"
                            src={image}
                            alt={item.title + " cover"} />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}