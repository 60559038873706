import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Lightbox({ visible, setVisible, images, index, setIndex, title }) {
    return (
        <div className={`lightbox ${visible ? "visible" : ""}`}>
            <div className="lightbox__background"
                onClick={() => setVisible(false)}></div>
            <div className="lightbox__picture">
                <div className="lightbox__picture__buttons">
                    <button className="lightbox__picture__buttons__item disabled">
                        <span>
                            {index + 1}
                        </span>
                        <span>
                            sur {images.length}
                        </span>
                    </button>
                    <button className="lightbox__picture__buttons__item"
                        onClick={() => setVisible(false)}>
                        Fermer
                        <FontAwesomeIcon icon="fa-solid fa-times" />
                    </button>
                </div>
                <img src={images[index]}
                    alt={title + " image de couverture"} />
                <div className={`lightbox__picture__buttons ${images.length === 1 ? "disabled" : ""}`}>
                    <button className="lightbox__picture__buttons__item"
                        onClick={() => setIndex(index - 1 < 0 ? images.length - 1 : index - 1)}>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                        Précédent
                    </button>
                    <button className="lightbox__picture__buttons__item"
                        onClick={() => setIndex(index + 1 == images.length ? 0 : index + 1)}>
                        Suivant
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                    </button>
                </div>
            </div>
        </div>
    )
}