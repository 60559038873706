import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { TypeAnimation } from 'react-type-animation';
import Polaroid from '../items/Polaroid';
import { Window } from '../../App';
import Picture1 from '../../assets/polaroids/tournage_avec_equipe.jpg';
import Picture2 from '../../assets/polaroids/photo_de_profil.jpg';
import Picture3 from '../../assets/polaroids/plateau_fond_vert.jpg';
import Picture4 from '../../assets/polaroids/eglise_tournage.jpg';
import Picture5 from '../../assets/polaroids/vitam_aeterna.png';
import Picture6 from '../../assets/polaroids/direction_acteur_hopital.jpg';
import Picture7 from '../../assets/polaroids/concentration_tournage.jpg';
import Picture8 from '../../assets/polaroids/basilique-notre-dame-garde.jpeg';
import Godard from '../../assets/Jean-Luc Godard.jpeg';

export default function Profile() {
    const { breakpoint } = useContext(Window);

    const [titleIsIntersecting, setTitleIsIntersecting] = useState(false);
    const [polaroidsIsIntersecting, setPolaroidsIsIntersecting] = useState(false);
    const [quoteIsIntersecting, setQuoteIsIntersecting] = useState(false);
    const [firstBlockIsIntersecting, setFirstBlockIsIntersecting] = useState(false);
    const [secondBlockIsIntersecting, setSecondBlockIsIntersecting] = useState(false);
    const [thirdBlockIsIntersecting, setThirdBlockIsIntersecting] = useState(false);
    const [fourthBlockIsIntersecting, setFourthBlockIsIntersecting] = useState(false);

    const titleRef = useRef(null);
    const polaroidsRef = useRef(null);
    const quoteRef = useRef(null);
    const firstBlockRef = useRef(null);
    const secondBlockRef = useRef(null);
    const thirdBlockRef = useRef(null);
    const fourthBlockRef = useRef(null);

    const polaroids = [
        {
            src: Picture8,
            alt: "Notre Dame de la Garde, Marseille",
            legend: "Le lieu de l'intrigue",
            format: "large",
            index: 1,
        },
        {
            src: Picture2,
            alt: "Photo du visage de Julien Tournissa",
            legend: "Le protagoniste",
            format: "small",
            index: 2,
        },
        {
            src: Picture3,
            alt: "Julien Tournissa assis devant un fond vert, la tête posée sur une caméra",
            legend: "Lui et son objet fétiche",
            format: "small",
            index: 3,
        },
        {
            src: Picture4,
            alt: "Scène de tournage de court-métrage dans une Église",
            legend: "Silence... ça tourne !",
            format: "large",
            index: 4,
        },
        {
            src: Picture5,
            alt: "Un homme est au milieu du couloir, dans le noir",
            legend: "Vitam Aeterna",
            format: "large",
            index: 5,
        },
        {
            src: Picture6,
            alt: "Julien Tournissa dirige son acteur enfant, allongé dans un lit d'hôpital",
            legend: "Une direction d'acteur exemplaire",
            format: "large",
            index: 6,
        },
        {
            src: Picture7,
            alt: "Julien Tournissa, la tête vers le bas portant un casque audio et une casquette",
            legend: "En pleine concentration",
            format: "large",
            index: 7,
        },
        {
            src: Picture1,
            alt: "Julien Tournissa et son équipe pendant un tournage",
            legend: "Le métier de ses rêves",
            format: "small",
            index: 8,
        },
    ];

    const titleObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setTitleIsIntersecting(entry.isIntersecting)
        }
    ), [titleRef]);

    const polaroidsObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setPolaroidsIsIntersecting(entry.isIntersecting)
        }
    ), [polaroidsRef]);

    const quoteObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setQuoteIsIntersecting(entry.isIntersecting)
        }
    ), [quoteRef]);

    const firstBlockObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setFirstBlockIsIntersecting(entry.isIntersecting)
        }
    ), [firstBlockRef]);

    const secondBlockObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setSecondBlockIsIntersecting(entry.isIntersecting)
        }
    ), [secondBlockRef]);

    const thirdBlockObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setThirdBlockIsIntersecting(entry.isIntersecting)
        }
    ), [thirdBlockRef]);

    const fourthBlockObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setFourthBlockIsIntersecting(entry.isIntersecting)
        }
    ), [fourthBlockRef]);

    useEffect(() => {
        titleObserver.observe(titleRef.current);
        polaroidsObserver.observe(polaroidsRef.current);
        quoteObserver.observe(quoteRef.current);
        firstBlockObserver.observe(firstBlockRef.current);
        secondBlockObserver.observe(secondBlockRef.current);
        thirdBlockObserver.observe(thirdBlockRef.current);
        fourthBlockObserver.observe(fourthBlockRef.current);

        return () => {
            titleObserver.disconnect();
            polaroidsObserver.disconnect();
            quoteObserver.disconnect();
            firstBlockObserver.disconnect();
            secondBlockObserver.disconnect();
            thirdBlockObserver.disconnect();
            fourthBlockObserver.disconnect();
        }
    }, []);

    return (
        <section id="profile"
            className={`profile category ${breakpoint}`}>
            <div className={`title ${breakpoint} ${titleIsIntersecting ? "animated" : ""}`}
                ref={titleRef}>
                <h2 className="title__h2">
                    Synopsis
                </h2>
            </div>
            <div className={`profile__polaroids ${breakpoint} ${polaroidsIsIntersecting ? "animated" : ""}`}
                ref={polaroidsRef}>
                {polaroids.filter(p => [].includes(p.index)).map((p, i) => (
                    <Polaroid key={i}
                        src={p.src}
                        alt={p.alt}
                        legend={p.legend}
                        format={p.format}
                        index={p.index} />
                ))}
            </div>
            <div className="category__body">
                <div className={`profile__block left ${breakpoint} ${firstBlockIsIntersecting ? "animated" : ""}`}
                    ref={firstBlockRef}>
                    <div className={`profile__block__polaroids ${breakpoint} ${firstBlockIsIntersecting ? "animated" : ""}`}>
                        {polaroids.filter(p => [1, 2].includes(p.index)).map(p => (
                            <Polaroid key={p.index}
                                src={p.src}
                                alt={p.alt}
                                legend={p.legend}
                                format={p.format}
                                index={p.index} />
                        ))}
                    </div>
                    Je suis un professionnel passionné par le monde de l'audiovisuel, qui puise ses principales inspirations
                    dans les années 90. Le camescope familial greffé aux mains, j'ai eu très tôt l'idée de filmer tout ce
                    que mes yeux voyaient.
                </div>
                <div className={`profile__block right ${breakpoint} ${secondBlockIsIntersecting ? "animated" : ""}`}
                    ref={secondBlockRef}>
                    <div className={`profile__block__polaroids ${breakpoint} ${secondBlockIsIntersecting ? "animated" : ""}`}>
                        {polaroids.filter(p => [3, 4].includes(p.index)).map(p => (
                            <Polaroid key={p.index}
                                src={p.src}
                                alt={p.alt}
                                legend={p.legend}
                                format={p.format}
                                index={p.index} />
                        ))}
                    </div>
                    Né dans ma cité phocéenne en 1999 et habitué au cri des mouettes jusqu'à mes 18 ans,
                    j'ai pourtant posé mes valises à Paris pour trois ans afin d'acquérir les codes du
                    milieu et le savoir-faire du métier de réalisateur.
                </div>
                <div className={`profile__block left ${breakpoint} ${thirdBlockIsIntersecting ? "animated" : ""}`}
                    ref={thirdBlockRef}>
                    <div className={`profile__block__polaroids ${breakpoint} ${thirdBlockIsIntersecting ? "animated" : ""}`}>
                        {polaroids.filter(p => [5, 6].includes(p.index)).map(p => (
                            <Polaroid key={p.index}
                                src={p.src}
                                alt={p.alt}
                                legend={p.legend}
                                format={p.format}
                                index={p.index} />
                        ))}
                    </div>
                    Depuis, bien revenu au plus près des vagues, j'exerce une
                    pluralité d'activités professionnelles qui font de moi un créatif autonome
                    et me font comprendre pourquoi j'ai choisi de suivre la voie que mon cœur réclamait.
                </div>
                <div className={`profile__block right ${breakpoint} ${fourthBlockIsIntersecting ? "animated" : ""}`}
                    ref={fourthBlockRef}>
                    <div className={`profile__block__polaroids ${breakpoint} ${fourthBlockIsIntersecting ? "animated" : ""}`}>
                        {polaroids.filter(p => [7, 8].includes(p.index)).map(p => (
                            <Polaroid key={p.index}
                                src={p.src}
                                alt={p.alt}
                                legend={p.legend}
                                format={p.format}
                                index={p.index} />
                        ))}
                    </div>
                    Cultivant mes références au travers des sept arts existants, j'essaie - au maximum - de
                    les additionner dans les idées que j'ai, pour les greffer à ma vision du Cinéma. Depuis, les films
                    dans ma tête s'enchaînent et pour tous ceux-là, je porte les deux casquettes : réalisateur et acteur.
                </div>
            </div>
            <blockquote className={`profile__quote ${breakpoint} ${quoteIsIntersecting ? "animated" : ""}`}
                ref={quoteRef}>
                <p dangerouslySetInnerHTML={{ __html: `«&nbsp;Je ne veux parler que de cinéma, pourquoi parler d'autre chose ? Avec le cinéma on parle de tout, on arrive à tout.&nbsp;»` }}></p>
                <footer>
                    — Jean-Luc Godard
                    <cite> (1930 - 2022)</cite>
                </footer>
                <img src={Godard}
                    alt="Jean-Luc Godard avec une cigarette dans les mains, il est pensif" />
            </blockquote>
        </section>
    )
}