import React, { useContext, useState, useEffect } from 'react';
import { onSnapshot, collection, where, query, limit } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Window } from '../../App';

export default function Header({ ressource }) {
    const navigateTo = useNavigate("");

    const { breakpoint, scrollPosition } = useContext(Window);

    const [ressourceTitle, setRessourceTitle] = useState("");

    useEffect(() => {
        const ressourcesUnsub = onSnapshot(query(collection(db, "ressources"), where("id", "==", ressource), limit(1)), (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setRessourceTitle(doc.data().title);
            });
        });

        return () => {
            ressourcesUnsub();
        };
    }, [ressource]);

    return (
        <header className={`header ${scrollPosition >= 700 ? "minified" : ""}`}>
            <div className="header__container">
                {ressource === "" ?
                    <button className="header__container__button"
                        onClick={() => navigateTo("/")}>
                        <FontAwesomeIcon icon="fa-solid fa-house" />
                        Retourner à l'accueil
                    </button>
                    : <button className="header__container__button"
                        onClick={() => navigateTo("/ressource/" + ressource)}>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                        Retourner à "{ressourceTitle}"
                    </button>
                }
            </div>
        </header>
    )
}