import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { Window } from '../../App';
import ProjectsItem from '../items/ProjectsItem';

export default function Projects() {
    const { breakpoint } = useContext(Window);

    const [titleIsIntersecting, setTitleIsIntersecting] = useState(false);
    const [ressources, setRessources] = useState([]);
    const [projects, setProjects] = useState([{
        id: "",
        ressource: "",
        title: "",
        description: "",
        images: [],
        videos: [],
        debutDate: "",
        endDate: "",
    }]);

    const titleRef = useRef(null);

    const titleObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setTitleIsIntersecting(entry.isIntersecting)
        }, { rootMargin: "-100px" }
    ), [titleRef]);

    useEffect(() => {
        titleObserver.observe(titleRef.current);

        return () => {
            titleObserver.disconnect();
        }
    }, []);

    useEffect(() => {
        const ressourcesUnsub = onSnapshot(collection(db, "ressources"), (querySnapshot) => {
            let ressources = [];

            querySnapshot.forEach(async (doc) => {
                ressources.push({
                    id: doc.id,
                    title: doc.data().title,
                    order: doc.data().order,
                });
            });

            setRessources(ressources);
        });

        const projectsUnsub = onSnapshot(collection(db, "projects"), (querySnapshot) => {
            let projects = [];

            querySnapshot.forEach(async (doc) => {
                projects.push({
                    id: doc.id,
                    ressource: doc.data().ressource,
                    title: doc.data().title,
                    description: doc.data().description,
                    images: doc.data().images,
                    videos: doc.data().videos,
                    debutDate: doc.data().debutDate,
                    endDate: doc.data().endDate,
                });
            });

            setProjects(projects);
        });

        return () => {
            ressourcesUnsub();
            projectsUnsub();
        };
    }, []);

    return (
        <section id="projects"
            className={`projects category ${breakpoint}`}>
            <div className={`title ${breakpoint} ${titleIsIntersecting ? "animated" : ""}`}
                ref={titleRef}>
                <h2 className="title__h2">
                    Ressources
                </h2>
            </div>
            <div className="projects__items">
                {ressources?.sort(function (a, b) { return new Date(a.order) - new Date(b.order) }).map(p => (
                    <ProjectsItem key={p.id}
                        item={p}
                        image={projects?.filter(prj => prj.ressource === p.id).sort(function (a, b) { return new Date(b.debutDate) - new Date(a.debutDate) })[0]?.images[0]}
                        count={projects?.filter(prj => prj.ressource === p.id).length}
                        path={"/ressource/"} />
                ))}
            </div>
        </section>
    )
}