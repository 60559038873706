import React, { useContext, useState, useEffect } from 'react';
import { onSnapshot, collection, where, query, limit } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Header from "./Header";
import Footer from "./Footer";
import { Window, Title } from '../../App';
import Lightbox from '../items/Lightbox';
import RessourcesLinks from '../items/RessourcesLinks';

export default function Project() {
    const { breakpoint } = useContext(Window);
    const { setTitle } = useContext(Title);

    const [id, setId] = useState(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
    const [project, setProject] = useState({
        id: "",
        ressource: "",
        title: "",
        description: "",
        images: [],
        videos: [],
        debutDate: "",
        endDate: "",
    });
    const [lightboxVisible, setLightboxVisible] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);

    const navigateTo = useNavigate("");
    const projectCategories = [
        "Portfolio",
        "Montage",
        "Régie",
        "VFX",
        "Photographie",
        "Parcours",
    ];
    const images = [
        "https://themotionpictureblog.files.wordpress.com/2018/01/best-movie-fight-scenes.jpg",
        "https://images.unsplash.com/photo-1532463788086-56a492a0b34a?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bG9uZ3xlbnwwfHwwfHx8MA%3D%3D",
        "https://64.media.tumblr.com/0893156ef3618b637e58fba209858d0a/d9d7ab0ce682a421-ae/s1280x1920/7a841ec2316f6c1d6d272c694c0e537bd93af87c.jpg",
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setTitle(project.title + " - Julien Tournissa | Réalisateur, cadreur, monteur et régisseur marseillais");
    }, [project]);

    useEffect(() => {
        const projectsUnsub = onSnapshot(query(collection(db, "projects"), where("id", "==", id), limit(1)), (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                const project = {
                    id: doc.data().id,
                    ressource: doc.data().ressource,
                    title: doc.data().title,
                    description: doc.data().description,
                    images: doc.data().images,
                    videos: doc.data().videos,
                    debutDate: doc.data().debutDate,
                    endDate: doc.data().endDate,
                };

                setProject(project);
            });
        });

        return () => {
            projectsUnsub();
        };
    }, [id]);

    const formatDatetime = (date) => {
        if (date === "Invalid date" || date === "" || date === null) {
            return "Aujourd'hui";
        }

        moment.locale('fr', {
            months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
            monthsParseExact: true,
        });

        return moment(date).format('MMMM YYYY');
    }

    return (
        <React.Fragment>
            <Header
                ressource={project?.ressource} />
            <Lightbox
                visible={lightboxVisible}
                setVisible={setLightboxVisible}
                images={project?.images}
                index={lightboxIndex}
                setIndex={setLightboxIndex}
                title={project?.title} />
            <div className="project category">
                <div className="ressource__buttons">
                    <RessourcesLinks
                        setNewId={setId}
                        ressource={project?.ressource} />
                </div>
                <div className="project__banner">
                    <img className="project__banner__img"
                        src={project?.images[0]} />
                    <div className="project__pictures">
                        {project?.images.map((i, index) => (
                            <img key={index}
                                className="project__pictures__img"
                                src={i}
                                alt={project?.title + " image de couverture"}
                                onClick={() => {
                                    setLightboxIndex(index);
                                    setLightboxVisible(true);
                                }} />
                        ))}
                    </div>
                </div>
                <div className={`title ${breakpoint} ${(project?.videos.length === 0 && project?.description !== '') ? 'text_filled' : ''}`}>
                    <h2 className="title__h2">
                        {project?.title}
                    </h2>
                    {project?.debutDate === project?.endDate ?
                        <p className="title__date">
                            {formatDatetime(project?.debutDate)}
                        </p>
                        : <p className="title__date">
                            {formatDatetime(project?.debutDate)} - {formatDatetime(project?.endDate)}
                        </p>
                    }
                </div>
                {project?.videos.length > 0 &&
                    <div className="project__video">
                        {project?.videos.map((video, index) => (
                            <iframe key={index} src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1">
                            </iframe>
                        ))}
                    </div>
                }
                <p className="project__description"
                    dangerouslySetInnerHTML={{ __html: project?.description }}>
                </p>
            </div>
            <Footer />
        </React.Fragment>
    )
}