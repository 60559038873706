import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Cloudinary } from "@cloudinary/url-gen";
import './styles/styles.scss';
import Home from './components/layout/Home';
import Ressource from './components/layout/Ressource';
import Project from './components/layout/Project';

export const Window = React.createContext(null);
export const Title = React.createContext(null);
export const Theme = React.createContext(null);

function App() {
  const cld = new Cloudinary({ cloud: { cloudName: 'dt3gjmvjh' } });

  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [title, setTitle] = useState("Accueil - Julien Tournissa | Réalisateur, cadreur, monteur et régisseur marseillais");
  const [theme, setTheme] = useState("dark");
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    document.body.classList.remove("dark");
    document.body.classList.remove("light");
    document.body.classList.add(theme);
  }, [theme]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setBreakpoint(window.innerWidth < 700 ? "mobile" : (window.innerWidth >= 1300 ? "desktop" : "tablet"));
    };

    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Title.Provider value={{ title: title, setTitle: setTitle }}>
          <Theme.Provider value={{ theme: theme, setTheme: setTheme }}>
            <Window.Provider value={{ breakpoint: breakpoint, scrollPosition: scrollPosition, setScrollPosition: setScrollPosition }}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/ressource/:id" element={<Ressource />} />
                <Route exact path="/ressource/project/:id" element={<Project />} />
              </Routes>
            </Window.Provider>
          </Theme.Provider>
        </Title.Provider>
      </Router>
    </React.Fragment>
  );
}

export default App;
library.add(fab, fas, far);
