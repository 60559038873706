import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Footer() {
    return (
        <section className="footer">
            <div className="footer__networks">
                <a className="footer__networks__item"
                    href="mailto:tournissa.julien@gmail.com"
                    title="Envoyez-moi un mail">
                    <FontAwesomeIcon icon="fa-solid fa-envelope" />
                </a>
                <a className="footer__networks__item"
                    href="https://twitter.com/_TazMarshall"
                    target="__blank"
                    rel="noopener noreferrer"
                    title="Suivez-moi sur X">
                    <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
                </a>
                <a className="footer__networks__item"
                    href="https://www.linkedin.com/in/julien-tournissa-76680519a"
                    target="__blank"
                    rel="noopener noreferrer"
                    title="Suivez-moi sur LinkedIn">
                    <FontAwesomeIcon icon="fa-brands fa-linkedin" />
                </a>
                <a className="footer__networks__item"
                    href="https://youtube.com/@TazMarshall?si=RUhYhwvEpgm9brtH"
                    target="__blank"
                    rel="noopener noreferrer"
                    title="Suivez-moi sur YouTube">
                    <FontAwesomeIcon icon="fa-brands fa-youtube" />
                </a>
                <a className="footer__networks__item"
                    href="https://www.instagram.com/taz_marshall/?next=%2F"
                    target="__blank"
                    rel="noopener noreferrer"
                    title="Suivez-moi sur Instagram">
                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                </a>
            </div>
            <span className="footer__span">
                © 2023 Julien Tournissa.<br />
                Tous droits réservés.
            </span>
            <span className="footer__span">
                Développé par <a className="footer__span__link"
                    href="https://www.linkedin.com/in/benoit-ricard-dev/"
                    target="__blank"
                    rel="noopener noreferrer">
                    Benoit Ricard
                </a>.
            </span>
        </section>
    )
}