import { useContext, useState, useRef, useMemo, useEffect } from "react";
import { TypeAnimation } from 'react-type-animation';
import { Window } from '../../App';
import Showreel from '../../assets/JT - Showreel.mp4';
import Background from '../../assets/JT - Background.webp';

export default function Intro() {
    const { breakpoint } = useContext(Window);

    const [titleIsIntersecting, setTitleIsIntersecting] = useState(false);

    const titleRef = useRef(null);

    const titleObserver = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setTitleIsIntersecting(entry.isIntersecting)
        }
    ), [titleRef]);

    useEffect(() => {
        titleObserver.observe(titleRef.current);

        return () => {
            titleObserver.disconnect();
        }
    }, []);

    return (
        <section className={`intro ${breakpoint}`}
            ref={titleRef}>
            <img className="intro__background"
                src={Background}
                alt="Image de fond" />
            <div className="intro__container">
                <h1 className="intro__title">
                    Julien Tournissa
                </h1>
                <div className="intro__baseline">
                    <span>[ʒy-liɛ̃-tuʁ-ni-sa]</span> n. p., <span>
                        masculin
                    </span>
                </div>
                <div className="intro__definition">
                    <TypeAnimation
                        sequence={['Se dit d\'un réalisateur.',
                            1500,
                            'Se dit d\'un cadreur.',
                            1500,
                            'Se dit d\'un monteur.',
                            1500,
                            'Se dit d\'un régisseur.',
                            1500,
                            'Se prononce avec l\'accent du Sud.',
                            1500]}
                        speed={75}
                        repeat={Infinity} />
                </div>
                <video className={`intro__video ${breakpoint} ${titleIsIntersecting ? "animated" : ""}`}
                    src={Showreel}
                    muted
                    autoPlay={true}
                    preload="auto"
                    loop={true}
                    disablePictureInPicture
                    playsInline>
                </video>
                <div className="intro__buttons">
                    <a className="intro__buttons__button"
                        href="#profile">
                        Synopsis
                    </a>
                    <a className="intro__buttons__button"
                        href="#projects">
                        Ressources
                    </a>
                    <a className="intro__buttons__button"
                        href="#devis">
                        Demander un devis
                    </a>
                </div>
            </div>
        </section>
    )
}