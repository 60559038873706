import React, { useContext, useState, useEffect } from 'react';
import { Window, Title, Theme } from '../../App';
import Profile from './Profile';
import Projects from './Projects';
import Intro from './Intro';
import Footer from './Footer';
import Devis from './Devis';

export default function Home() {
    const { setTitle } = useContext(Title);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setTitle("Accueil - Julien Tournissa | Réalisateur, cadreur, monteur et régisseur marseillais");
    }, []);

    return (
        <React.Fragment>
            <Intro />
            <Profile />
            <Projects />
            <Devis />
            <Footer />
        </React.Fragment>
    )
}