import React, { useContext, useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, collection } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { Window } from "../../App";

export default function Devis() {
    const { breakpoint } = useContext(Window);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [empty, setEmpty] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleEmail = async (e) => {
        e.preventDefault();

        const newMail = {
            id: uuidv4(),
            from: `${name} <${email}>`,
            to: "tournissa.julien@gmail.com",
            message: {
                subject: "Demande de devis sur julientournissa.fr",
                html: message.replace(/\r?\n/g, "<br />"),
            },
        };

        if (phone === "") {
            try {
                const mailRef = doc(collection(db, "mails"), newMail.id);
                await setDoc(mailRef, newMail);
            } catch (error) {
                setError(true);
            }
        }

        setSuccess(true);
    }

    return (
        <div id="devis"
            className="devis category">
            <div className={`title ${breakpoint}`}>
                <h2 className="title__h2">
                    Demander un devis
                </h2>
            </div>
            {!error && !success ?
                <form className="category__body"
                    onSubmit={handleEmail}>
                    <p className="devis__disclaimer">
                        Tous les champs sont requis.<br />
                        Votre adresse e-mail doit être réelle.<br />
                        {empty &&
                            <span>
                                Veuillez remplir tous les champs avant de valider.
                            </span>
                        }
                    </p>
                    <div className="devis__field">
                        <label htmlFor="your-name"
                            className="devis__field__label">
                            Votre nom complet
                        </label>
                        <input id="your-name"
                            className="devis__field__input"
                            required
                            type="text"
                            placeholder="Steven Spielberg"
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="devis__field">
                        <label htmlFor="your-mail"
                            className="devis__field__label">
                            Votre adresse e-mail
                        </label>
                        <input id="your-mail"
                            className="devis__field__input"
                            required
                            type="mail"
                            placeholder="steven.spielberg@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="devis__field alt">
                        <label htmlFor="your-name"
                            className="devis__field__label">
                            Votre numéro de téléphone
                        </label>
                        <input id="your-name"
                            className="devis__field__input"
                            tabIndex={-1}
                            type="tel"
                            placeholder="0601020304"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className="devis__field">
                        <label htmlFor="your-message"
                            className="devis__field__label">
                            Votre message
                        </label>
                        <textarea id="your-message"
                            className="devis__field__input"
                            required
                            placeholder="Commencez à écrire ici..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}>
                        </textarea>
                    </div>
                    <button className="devis__submit"
                        onClick={() => setEmpty(name == "" || email == "" || message == "" ? true : false)}>
                        Envoyer le message
                    </button>
                </form>
                : <div className="category__body">
                    {error ?
                        <p className="devis__error">
                            Une erreur est survenue lors de l'envoi du mail. Veuillez réessayer plus tard ou me contacter via les réseaux sociaux présents dans le bas de page ci-dessous.
                        </p>
                        : <p className="devis__success">
                            Votre mail m'a bien été parvenu, je vous y répondrai au plus vite. N'hésitez pas également à me contacter ou me suivre sur les réseaux sociaux présents dans le bas de page ci-dessous.
                        </p>
                    }
                </div>
            }
        </div>
    )
}