import React, { useContext } from 'react';
import { Window } from '../../App';

export default function Polaroid({ src, alt, legend, index, format }) {
    const { breakpoint } = useContext(Window);

    return (
        <React.Fragment>
            <div className={`polaroid i-${index} ${format} ${breakpoint}`}>
                <img className="polaroid__image"
                    src={src}
                    alt={alt} />
            </div>
        </React.Fragment>
    )
}