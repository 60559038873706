import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { onSnapshot, collection, where, limit } from 'firebase/firestore';
import { db } from '../../utils/firebase';

export default function RessourcesLinks({ setNewId, ressource }) {
    const [id, setId] = useState(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
    const [ressources, setRessources] = useState([]);

    const navigateTo = useNavigate("");

    useEffect(() => {
        const ressourcesUnsub = onSnapshot(collection(db, "ressources"), (querySnapshot) => {
            let ressources = [];

            querySnapshot.forEach(async (doc) => {
                ressources.push({
                    id: doc.id,
                    title: doc.data().title,
                });
            });

            setRessources(ressources);
        });

        return () => {
            ressourcesUnsub();
        };
    }, [id]);

    return (
        <React.Fragment>
            {ressources.filter(res => res.id !== ressource).map(ressource => (
                <button key={ressource.id}
                    className="intro__buttons__button"
                    onClick={() => {
                        navigateTo("/ressource/" + ressource.id);
                        setId(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
                        setNewId(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
                    }}>
                    {ressource.title}
                </button>
            ))}
        </React.Fragment>
    )
}